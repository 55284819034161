var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-layout',{staticClass:"mlayout"},[_c('a-layout',[_c('a-layout-content',{staticClass:"pc indexLayoutContent",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"page mlist flex-col justify-start",staticStyle:{"height":"auto"}},[_c('top-a',{attrs:{"kind":``,"topbarhide":_vm.topbarhide,"tb":_vm.tb,"w750":_vm.w750,"topbarOpacity":_vm.topbarOpacity,"lag":_vm.lag}}),_c('div',{staticClass:"group_0_3 flex-col"},[_c('div',{staticClass:"g03 flex-col",style:(``)},[_c('img',{staticClass:"image_20",staticStyle:{"width":"1760px","height":"1px","margin":"0 auto"},attrs:{"referrerpolicy":"no-referrer","src":"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng43e13759fd61200dd2aec8f3f42b50d1271142b403a2181742c322bf702eb876"}}),_c('div',{staticClass:"g03in flex-col"},[_c('div',{staticClass:"gbar flex-col justify-between",staticStyle:{"margin-top":"240px"}},[_c('div',{staticClass:"gtitles flex-col"},[_c('span',{staticClass:"text_13",staticStyle:{"color":"rgba(50, 50, 50, 1)","font-size":"88px","height":"88px","line-height":"88px"}},[_c('img',{staticStyle:{"height":"80px"},attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/title4b.png"}})])]),_c('div',{staticClass:"gdes flex-col"},[_c('div',{staticClass:"textline",staticStyle:{"margin-top":"30px","color":"rgba(50, 50, 50, 1)","font-size":"24px","line-height":"24px"}},[_vm._v(" 趣野活动精彩不断，一起体验趣野无限。 ")])]),_c('div',{staticClass:"selectbar",staticStyle:{"width":"416px"}},[_c('div',{class:`sbitem ${_vm.sbindex == '' ? 'sbselected' : ''}`,on:{"click":() => {
                          _vm.sbselect('');
                        }}},[_vm._v(" 全部 ")]),_c('div',{class:`sbitem ${_vm.sbindex == '0' ? 'sbselected' : ''}`,on:{"click":() => {
                          _vm.sbselect('0');
                        }}},[_vm._v(" 招募中 ")]),_c('div',{class:`sbitem ${_vm.sbindex == '1' ? 'sbselected' : ''}`,on:{"click":() => {
                          _vm.sbselect('1');
                        }}},[_vm._v(" 精彩回放 ")])])]),_c('div',{staticClass:"g04mlist"},[_vm._l((_vm.clist),function(item,index){return [_c('div',{key:index,class:`g04div`},[_c('div',{staticClass:"g04item",style:(`background-image: url(${
                            _vm.baseUrl + item.imgurl
                          });`),on:{"click":() => {
                              _vm.godetall(item.url, item.pname, item.kind, item.id);
                            }}},[(item.kind == 40)?[(item.state == '0' )?_c('div',{staticClass:"zmb zmbf"},[_vm._v("敬请期待")]):_vm._e(),(item.state == '1' )?_c('div',{staticClass:"zmb"},[_vm._v("招募中")]):_vm._e(),(item.state == '2' )?_c('div',{staticClass:"zmb zmbs"},[_vm._v("报名截止")]):_vm._e(),(item.state == '3' )?_c('div',{staticClass:"zmb zmbs"},[_vm._v("已结束")]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"g04info"},[_c('div',{staticClass:"g04title",domProps:{"innerHTML":_vm._s(item.pname)}}),_c('div',{staticClass:"g04des flex-row",domProps:{"innerHTML":_vm._s(item.des)}})])])]})],2),_c('div',{staticClass:"pageselect"},[_c('a-pagination',{attrs:{"size":"small","total":_vm.ctotal,"page-size":_vm.cpageSize},on:{"change":_vm.PageonChange},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)]),_c('div',{staticClass:"backimg"})])]),_c('footer-a',{attrs:{"kind":``,"lag":_vm.lag}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }