var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group_8 flex-col windowheight",style:(`${
    _vm.kind != 'index'
      ? '-webkit-mask-image: none;position: relative;top:0px;'
      : ''
  }-webkit-mask-position: ${_vm.g8top}px ${_vm.g8left}px;`)},[_c('div',{staticClass:"g8 flex-col"},[_c('div',{staticClass:"g8in flex-col"},[_vm._m(0),_c('div',{staticClass:"g8banner flex-row"},[_vm._l((_vm.blinks),function(item,index){return [_c('div',{key:index,staticClass:"blist flex-col"},[_c('div',{staticClass:"titem",on:{"click":function($event){return _vm.goSelect(item.id)}}},[_vm._v(" "+_vm._s(item.title)+" ")]),_vm._l((item.list),function(item2,index2){return [_c('div',{key:index2,staticClass:"bitem",on:{"click":function($event){return _vm.goSelectb(item.id, item2.id)}}},[_vm._v(" "+_vm._s(item2.title)+" ")])]})],2)]})],2),_c('div',{staticClass:"g8bigtitle"}),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"backimg"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g8logo"},[_c('img',{staticClass:"w-logo-img",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/limgs/logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"g8blan"},[_c('div',{staticClass:"textline"},[_vm._v(" 邮箱：yami.hu@all-fields.com         ")]),_c('div',{staticClass:"textline"},[_c('span',{staticStyle:{"opacity":"0"}},[_vm._v("隐私政策")]),_c('span',{staticStyle:{"margin-left":"27px","opacity":"0"}},[_vm._v("服务条款")]),_c('span',[_vm._v("沪ICP备2020037860号-2")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textarea",staticStyle:{"right":"80px"}},[_c('img',{staticClass:"image_505",staticStyle:{"width":"50rem","height":"1px","margin":"0px 0 0 0","opacity":".5"},attrs:{"referrerpolicy":"no-referrer","src":"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng378c9d7e85ae34a74b059b6e2ca95f758374832a11efecbf119443472d4a2b08"}}),_c('div',{staticClass:"tat",staticStyle:{"margin-top":"0px"}},[_vm._v(" 想获取更多户外资讯？"),_c('br'),_vm._v("了解我们趣野人？"),_c('br'),_vm._v("跟我们一起参与活动？"),_c('br'),_vm._v("马上扫码 公众号 / 小红书 / 社群二维码，加入我们趣野人阵列！ ")]),_c('div',{staticClass:"qrdiv"},[_c('div',{staticClass:"qritem"},[_c('img',{staticClass:"qr0",attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/qr0.png"}}),_c('span',[_vm._v("趣野公众号")])]),_c('div',{staticClass:"qritem"},[_c('img',{staticClass:"qr0",attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/qr1.png"}}),_c('span',[_vm._v("趣野社群")])]),_c('div',{staticClass:"qritem"},[_c('img',{staticClass:"qr0",attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/qr2.png"}}),_c('span',[_vm._v("趣野小红书")])])])])
}]

export { render, staticRenderFns }