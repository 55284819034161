<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content class="pc indexLayoutContent" style="padding: 0px">
          <div class="page mlist flex-col justify-start" style="height: auto">
            <top-a
              :kind="``"
              :topbarhide="topbarhide"
              :tb="tb"
              :w750="w750"
              :topbarOpacity="topbarOpacity"
              :lag="lag"
            ></top-a>

            <div class="group_0_3 flex-col">
              <div class="g03 flex-col" :style="``">
                <img
                  class="image_20"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng43e13759fd61200dd2aec8f3f42b50d1271142b403a2181742c322bf702eb876"
                  style="width: 1760px; height: 1px; margin: 0 auto"
                />
                <div class="g03in flex-col">
                  <div
                    class="gbar flex-col justify-between"
                    style="margin-top: 240px"
                  >
                    <div class="gtitles flex-col">
                      <span
                        class="text_13"
                        style="
                          color: rgba(50, 50, 50, 1);
                          font-size: 88px;
                          height: 88px;
                          line-height: 88px;
                        "
                        ><img
                          src="https://cdn.moheweb.com/vdb/lweb/imgs/title4b.png"
                          style="height: 80px"
                      /></span>
                    </div>
                    <div class="gdes flex-col">
                      <div
                        class="textline"
                        style="
                          margin-top: 30px;
                          color: rgba(50, 50, 50, 1);
                          font-size: 24px;
                          line-height: 24px;
                        "
                      >
                        趣野活动精彩不断，一起体验趣野无限。
                      </div>
                    </div>

                    <div class="selectbar" style="width: 416px">
                      <div
                        :class="`sbitem ${sbindex == '' ? 'sbselected' : ''}`"
                        @click="
                          () => {
                            sbselect('');
                          }
                        "
                      >
                        全部
                      </div>
                      <div
                        :class="`sbitem ${sbindex == '0' ? 'sbselected' : ''}`"
                        @click="
                          () => {
                            sbselect('0');
                          }
                        "
                      >
                        招募中
                      </div>
                      <div
                        :class="`sbitem ${sbindex == '1' ? 'sbselected' : ''}`"
                        @click="
                          () => {
                            sbselect('1');
                          }
                        "
                      >
                        精彩回放
                      </div>
                    </div>
                  </div>

                  <div class="g04mlist">
                    <template v-for="(item, index) in clist">
                      <div :class="`g04div`" :key="index">
                        <!-- <router-link
                          :to="`/mdetall/${item.kind}/${item.id}/${lag}`"
                        > -->
                          <div
                            class="g04item"
                            :style="`background-image: url(${
                              baseUrl + item.imgurl
                            });`"
                            @click="
                              () => {
                                godetall(item.url, item.pname, item.kind, item.id);
                              }
                            "
                          >
                            <template v-if="item.kind == 40">
                              <!-- <div
                                class="zmb zmbf"
                                v-if="item.startdate > nowdate"
                              >
                                敬请期待
                              </div>
                              <div
                                class="zmb"
                                v-if="
                                  item.startdate < nowdate &&
                                  item.finaldate > nowdate
                                "
                              >
                                招募中
                              </div>
                              <div
                                class="zmb zmbs"
                                v-if="item.finaldate < nowdate"
                              >
                                已结束
                              </div> -->

                                <div class="zmb zmbf" v-if="item.state == '0' ">敬请期待</div>
                                <div class="zmb" v-if="item.state == '1' ">招募中</div>
                                <div class="zmb zmbs" v-if="item.state == '2' ">报名截止</div>
                                <div class="zmb zmbs" v-if="item.state == '3' ">已结束</div>
                                
                            </template>
                          </div>

                          <div class="g04info">
                            <div class="g04title" v-html="item.pname"></div>
                            <div
                              class="g04des flex-row"
                              v-html="item.des"
                            ></div>
                          </div>
                        <!-- </router-link> -->
                      </div>
                    </template>
                  </div>
                  <div class="pageselect">
                    <!-- <div class="toleft" @click="swiper1Prev"></div>
                    <div class="spages">
                      <div class="pnumber">1</div>
                      <div class="pnumber">2</div>
                      <div class="pnumber">3</div>
                    </div>
                    <div class="toright" @click="swiper1Next"></div> -->
                    <a-pagination
                      size="small"
                      v-model="current"
                      :total="ctotal"
                      :page-size="cpageSize"
                      @change="PageonChange"
                    >
                    </a-pagination>
                  </div>
                </div>

                <div class="backimg"></div>
              </div>
            </div>

            <footer-a :kind="``" :lag="lag"></footer-a>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  fetchClass,
  fetchCase,
  fetchFriend,
  fetchBase,
  fetchAwards,
} from "../api/index";
import FooterA from "../components/FooterA.vue";
import TopA from "../components/TopA.vue";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel,Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {
    FooterA,
    TopA,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      current: 1,
      cpageSize: 12,
      ctotal: 0,

      pagesize: 12,
      pageno: 0,
      total: 40,
      sbindex: "",

      clist: [],

      pageHeight: 0,
      tbanimate: "down",
      winHeight: 0,
      topbarhide: 0,
      id: "0",
      lag: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      frienddata2: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      swiper0: null,

      swiper1: null,

      swiper2: null,

      swiper3: null,

      swiper03: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      gtitle: "整合内容营销&nbsp;+&nbsp;智能数据平台&nbsp;+&nbsp;奢侈品营销",
      gdesc:
        "IPmotion（上海派志）作为一类以独特创意DNA驱动的公司，而区别于一般的“广告创意公司”。<br />成立十多年来，IPmotion（上海派志）在人工智能、IT电子、医药、汽车等垂直领域积累了丰富的经验案例，并助力企业、品牌的营销转型和数字转型的全过程。为全球500强与国内行业领先品牌，如：智能科技类企业：世界人工智能大会、世界制造业大会、华为、远景集团、医药类企业：阿斯利康、基石药业、BD医疗、奢侈品类企业：香奈儿、MHD、帕玛强尼等提供全案创意营销代理服务和线下解决方案。",
      mkinds: [],
      ppindex: -1,
      pplist: [],
      bselectindex: -1,
      blinks: [
        {
          id: "0",
          title: "趣野风向",
          url: "",
          class: "jianjie",
          list: [
            {
              title: "PM.看看世界",
              des: "了解趣野历史相关活动",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/0bbd5b52752e40d7bd05e634f2ff35a3_mergeImage.png",
              id: "00",
            },
            {
              title: "趣野故事会",
              des: "了解趣野历史相关活动",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfde342438c247fab8fca0fa5adc4e4889cb2b837a5debc0f0799d0f0f5d3c4c8",
              id: "01",
            },
            {
              title: "趣野视角",
              des: "了解趣野历史相关活动",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng76c7ee71154fce1d4550cbe476a1aa62bd0a0fa0efff11576097faa2e329bc11",
              id: "02",
            },
          ],
        },
        {
          id: "1",
          title: "趣野伙伴",
          url: "",
          class: "huoban",
          list: [
            {
              title: "KOL日常",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ae86cb0afc6b4b0eb7e811e4cec4908e_mergeImage.png",
              id: "10",
            },
            {
              title: "合作品牌",
              des: "了解更多",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge66575cfe665894eb1ba232fc64948216912902ac68d399dfe1af1df00895add",
              id: "11",
            },
          ],
        },
        {
          id: "2",
          title: "趣野好物",
          url: "",
          class: "fuwuneirong",
          list: [
            {
              title: "好物推荐",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
              id: "20",
            },
            {
              title: "购物平台",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
              id: "21",
            },
          ],
        },
        {
          id: "3",
          title: "趣野SHOW",
          url: "",
          class: "anli",
          list: [
            {
              title: "现场SHOW什么？",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/90a0e506ec82439ba8b9f3a3b9cafe6f_mergeImage.png",
              id: "30",
            },
            {
              title: "新鲜SHOW闻",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/efed9ea5f9d94fe999ad67d5274954b7_mergeImage.png",
              id: "31",
            },
            {
              title: "Ta们在SHOW",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/cc8ff4aedae34e61931b49962afb8a2c_mergeImage.png",
              id: "32",
            },
            {
              title: "想来SHOW一下么？",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f5f6ac4ceb9e488f9a8a4bde7d2b358f_mergeImage.png",
              id: "33",
            },
          ],
        },
        {
          id: "4",
          title: "趣野CLUB",
          url: "",
          class: "lianxiwomen",
          list: [
            {
              title: "趣野人集合",
              des: "了解更多",
              url: "https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ae86cb0afc6b4b0eb7e811e4cec4908e_mergeImage.png",
              id: "40",
            },
            {
              title: "精彩回放",
              des: "了解更多",
              url: "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge66575cfe665894eb1ba232fc64948216912902ac68d399dfe1af1df00895add",
              id: "41",
            },
          ],
        },
      ],
      sectionindex: 0,
      sections: [],
      chsections: [
        {
          img: "i-logo-0.png",
          title: "展览展厅&amp;活动",
          desc: "提供前沿的创意理念，及线下制作的一体化服务",
          tip: ["临时展厅", "常设展厅", "多媒体互动", "交互内容", "线下活动"],
        },
        {
          img: "i-logo-1.png",
          title: "智能平台&amp;多媒体互动",
          desc: "提供服务与解决方案的智能科技应用平台",
          tip: ["数字营销", "社会化传播", "TVC视频", "宣传片"],
        },
        {
          img: "i-logo-2.png",
          title: "智能平台&amp;多媒体互动",
          desc: "提供服务与解决方案的智能科技应用平台",
          tip: ["智能会务系统", "智能云展", "数字孪生", "智能机器人"],
        },
        {
          img: "i-logo-3.png",
          title: "奢侈品营销",
          desc: "结合线下制作、运营于一体的互动体验",
          tip: ["促销活动", "体验营销"],
        },
      ],
      ensections: [
        {
          img: "i-logo-0.png",
          title: "Exhibition & Event",
          desc: "Provide cutting-edge creative concepts and integrated services for offline production",
          tip: ["Exhibition", "Muti-Media", "Interactive", "Physical Event"],
        },
        {
          img: "i-logo-1.png",
          title: "Digital Marketing & <br />Video Creation",
          desc: "Covering both online and offline,Integrate omnichannel promotion and dissemination",
          tip: [
            "Digital marketing",
            "Social Communication",
            "TVC Video",
            "Promotional video",
          ],
        },
        {
          img: "i-logo-2.png",
          title: "Intelligence Platform & <br />Multi Interactive",
          desc: "An intelligent technology application platform that provides services and solutions",
          tip: [
            "Intelligent conference system",
            "Intelligent Virtual Event",
            "Digital twin",
            "intelligent robot",
          ],
        },
        {
          img: "i-logo-3.png",
          title: "Luxury",
          desc: "Interactive experience combining offline production and operation",
          tip: ["Promotional activities", "Experience Marketing"],
        },
      ],
      data: [],
      dataloading: 0,
      pageSize: 12,
      query: {
        class1: [],
        class2: [],
      },
      alive: true,
      adata: [],
      awardsindex: 0,

      opy1: 0,
      opy2: 0,
      opy3: 0,
      opy4: 0,
      opy5: 0,
      opy6: 0,
      opy7: 0,
      opy8: 0,

      group1h: 0,
      group2h: 0,
      group3h: 0,
      group4h: 0,
      group5h: 0,
      group6h: 0,
      group7h: 0,

      tb: true,
      masktop: 0,
      masksize: 0,
      g8top: 0,
      g8left: 0,
      nowdate: '',
    };
  },

  activated() {
    let that = this;
    console.log("onActivated");
    let scrollvalue = global.getIndexscroll();
    if (scrollvalue > 0) {
      $(".main").scrollTop(scrollvalue);
    }

    if (scrollvalue > 400) {
      this.topbarOpacity = (scrollvalue - 400) / 200;
      if (this.topbarOpacity > 1) {
        this.topbarOpacity = 1;
      }
    } else {
      this.topbarOpacity = 0;
    }

    that.resizepage();

    setTimeout(() => {
      let classtext = global.getIndexDclass();
      // console.log("classtext:", classtext);
      if (classtext != "") {
        that.goDiv(classtext, "");
      }
      // let video0 = document.getElementById("video0");
      // video0.play();
    }, 1000);
    this.alive = true;
    this.handleScroll;
  },
  deactivated() {
    console.log("deactivated:");
    this.alive = false;
  },

  mounted() {
    let that = this;

    let id = this.$route.params.id;

    let lag = this.$route.params.lag;

    if (id) {
      this.sbindex = id;
      this.kind = `4${id}`;
    }

    if (lag) {
      this.lag = lag;
    }

    console.log("this.lag:", this.lag);

    if (this.lag == "en") {
      this.sections = this.ensections;
    } else {
      this.sections = this.chsections;
    }
    global.setIndexDclass("");
    global.setIndexscroll(0);
    that.handleScroll;
    that.resizepage();
    this.$el.addEventListener("scroll", that.handleScroll, true);
    // window.addEventListener("resize", that.handleResize, true);

    window.onresize = ()=>{
      that.handleResize();
    }

    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
      $(document).on("click", ".closesw3", function () {
        $(".showawardsdiv").fadeTo(300, 0, () => {
          $(".showawardsdiv").css({ left: "-100%" });
        });
      });
    }, 1000);

    this.fetch0();
    this.nowdate = this.getNowDate();
  },
  beforeDestroy() {
    window.onresize = null;
    console.log("index beforeDestroy");
  },
  beforeCreate() {
    let that = this;
  },
  methods: {

    godetall(link, title, kind, id) {
      // alert(title+link);
      if (link != "") {
        window.location.href = link;
      } else {
        if (title != "") {
          this.$router.push(`/mdetall/${kind}/${id}/${this.lag}`);
        }
      }
    },
    getNowDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      month = month > 9 ? month : "0" + month;
      day = day < 10 ? "0" + day : day;
      var today = year + "/" + month + "/" + day;
      return today;
    },
    
    sbselect(index) {
      this.sbindex = index;
      this.kind = `4${index}`;
      if (index == "") {
        this.kind = `40,41,42`;
      }
      console.log(this.sbindex);
      this.fetch0();
    },

    fetch0(
      params = {
        show: 1,
        kind: this.kind,
        pagesize: this.cpageSize,
        page: this.current,
        query: this.query,
      }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist = res.results.list;
          that.ctotal = res.results.total;
        } else {
          that.clist = [];
          that.ctotal = 0;
        }
        console.log("clist:", that.clist);
      });
    },

    PageonChange(current) {
      this.current = current;
      this.fetch0();
    },

    swiper1Prev() {
      this.swiper03.slidePrev(500);
    },
    swiper1Next() {
      this.swiper03.slideNext(500);
    },

    handleScroll(e) {
      // console.log("handleScroll");

      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        // console.log("e.target.scrollTop:",e.target.scrollTop);
        global.setIndexscroll(winHeight);

        let group1h = $(".group_1").height();
        let group2h = $(".group_2").height();
        let group3h = $(".group_3").height();
        let group4h = $(".group_4").height();
        let group5h = $(".group_5").height();
        let group6h = $(".group_6").height();
        let group7h = $(".group_7").height();
        this.group1h = group1h;
        this.group2h = group2h;
        this.group3h = group3h;
        this.group4h = group4h;
        this.group5h = group5h;
        this.group6h = group6h;
        this.group7h = group7h;

        this.tb = true;

        if (
          this.winHeight >
          this.pageHeight +
            this.pageHeight +
            this.pageHeight +
            group2h +
            group4h +
            group6h +
            2000 +
            2000
        ) {
          console.log("down");
          this.tbanimate = "up";
          // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
          $(".topbar").removeClass("downtopbar");
          $(".topbar").addClass("uptopbar");
        }

        if (winHeight > 120) {
          if (this.winHeight > winHeight && this.tbanimate != "down") {
            this.tbanimate = "down";
            // $(".topbar").stop(true).animate({ top: "0px" }, 100);
            $(".topbar").removeClass("uptopbar");
            $(".topbar").addClass("downtopbar");
          } else if (this.winHeight < winHeight && this.tbanimate != "up") {
            this.tbanimate = "up";
            // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
            $(".topbar").removeClass("downtopbar");
            $(".topbar").addClass("uptopbar");
          }
        }

        this.winHeight = winHeight;
        if (winHeight > 400) {
          this.topbarOpacity = (winHeight - 400) / 200;
          if (this.topbarOpacity > 1) {
            this.topbarOpacity = 1;
          }
        } else {
          this.topbarOpacity = 0;
        }
      }
    },

    myswiper2() {
      let that = this;

      setTimeout(() => {
        this.swiper2 = new Swiper(".sc2", {
          slidesPerView: 4,
          // slidesPerView: 2,
          // loop: true,
          // centeredSlides: true,
          spaceBetween: 0,
          freeMode: true,
          // grabCursor: true,
          // pagination: {
          //   el: ".swiper-pagination",
          //   clickable: true,
          // },

          on: {
            transitionEnd: function () {
              // that.selectIndex = this.activeIndex;

              // console.log("this", this);

              // console.log("this.selectIndex:", this.realIndex);
              let w =
                parseInt(
                  ((parseInt(this.realIndex) + 4) / that.g00list.length) * 1000
                ) / 10;
              // console.log("www:", w);
              $(".sc2bar").css({ width: w + "%" });
            },
            init: function (swiper) {
              let w = parseInt((4 / that.g00list.length) * 1000) / 10;
              $(".sc2bar").css({ width: w + "%" });
            },
          },
        });

        this.swiper03 = new Swiper(".sc03", {
          slidesPerView: 1,
          // slidesPerView: 2,
          // loop: true,
          // centeredSlides: true,
          spaceBetween: 0,
          // freeMode: true,
          // grabCursor: true,
          pagination: {
            el: ".sp03",
            clickable: true,
          },

          on: {
            transitionEnd: function () {},
            init: function (swiper) {},
          },
        });
      }, 10);
    },

    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },
    fetch(
      params = { show: 1, hot: 1, pagesize: this.pageSize, query: this.query }
    ) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchCase(params).then((res) => {
        // console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
        // console.log("data:", that.data);
        // setTimeout(() => {
        //   that.swiper.update();
        // }, 100);
      });
    },
    fetchf(params = { show: 1, pagesize: 1000 }) {
      let that = this;
      this.loading = true;

      fetchFriend(params).then((res) => {
        // console.log("fetchFriend:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        that.frienddata = res.results.list;

        let frienddata2 = [];

        let lindex = 0;
        res.results.list.map((item, index) => {
          if (index % 4 == 0) {
            frienddata2[lindex] = [];
          }
          frienddata2[lindex].push(item);
          if (index % 4 == 3) {
            lindex++;
          }
        });
        that.frienddata2 = frienddata2;

        if (that.w750 == 0) {
        } else {
        }
      });
    },
    fetcha(params = { show: 1, hot: 1, pagesize: this.pageSize }) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchAwards(params).then((res) => {
        console.log("fetchAwards:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;

        if (res.results.list) {
          that.adata = res.results.list;
        } else {
        }
        that.pagination = pagination;
        // console.log("data:", that.data);
        // setTimeout(() => {
        //   that.swiper3.update();
        // }, 100);
      });
    },

    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        // console.log("fetchBase:", res.results);
        that.loading = false;
        that.basedata = res.results;

        that.blinks[0].title = res.results.t0;
        that.blinks[1].title = res.results.t1;
        that.blinks[2].title = res.results.t2;
        that.blinks[3].title = res.results.t3;
        that.blinks[4].title = res.results.t4;
        that.blinks[5].title = res.results.t5;

        if (that.lag == "en") {
          that.blinks[0].title = res.results.ent0;
          that.blinks[1].title = res.results.ent1;
          that.blinks[2].title = res.results.ent2;
          that.blinks[3].title = res.results.ent3;
          that.blinks[4].title = res.results.ent4;
          that.blinks[5].title = res.results.ent5;

          that.basedata.t0 = res.results.ent0;
          that.basedata.t1 = res.results.ent1;
          that.basedata.t2 = res.results.ent2;
          that.basedata.t3 = res.results.ent3;
          that.basedata.t4 = res.results.ent4;
          that.basedata.t45 = res.results.ent45;
          that.basedata.t5 = res.results.ent5;
          that.basedata.t6 = res.results.ent6;
          that.basedata.t7 = res.results.ent7;
          that.basedata.t8 = res.results.ent8;
          that.basedata.t9 = res.results.ent9;
          that.basedata.t10 = res.results.ent10;

          that.basedata.tel = res.results.entel;
          that.basedata.adr = res.results.enadr;
          that.basedata.email = res.results.enemail;
          that.basedata.mtitle = res.results.enmtitle;
          that.basedata.mcontent = res.results.enmcontent;
          that.basedata.aititle = res.results.enaititle;
          that.basedata.aicontent = res.results.enaicontent;
          that.basedata.aicontent0 = res.results.enaicontent0;
          that.basedata.aicontent1 = res.results.enaicontent1;
          that.basedata.aicontent2 = res.results.enaicontent2;
          that.basedata.aicontent3 = res.results.enaicontent3;
          that.basedata.aicontent4 = res.results.enaicontent4;
        }
      });
    },

    selectclass2(id) {
      this.nopplistshow = 0;
      this.dataloading = 1;
      this.thisclass2 = id;
      // console.log(id);
      this.query = {
        class1: this.thisclass1,
        class2: [id],
      };
      this.data = [];
      setTimeout(() => {
        this.fetch();
      }, 500);
    },
    getClassList() {
      const that = this;
      fetchClass().then((res) => {
        that.classlist = res.results.list;

        // console.log("classlist:", that.classlist);

        that.class2list = this.classlist.filter((it) => {
          return this.thisclass1 == it.id;
        })[0]["list"];

        // console.log("class2list:", that.class2list);

        let mkinds = [];

        that.class2list.map((item) => {
          let cname = item.cname;
          if (that.lag == "en") {
            cname = item.encname;
          }
          mkinds.push({ name: cname, id: item.id });
        });

        that.mkinds = mkinds;

        console.log("mkinds:", this.mkinds);
      });
    },

    gowebsite(url) {
      window.open(url);
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },

    resizeViewport() {
      let that = this;
      let bl = window.innerHeight / window.innerWidth;
      if (bl > 1.2) {
        this.sectionindex = 0;
        this.m_group_2_height = this.pageHeight;
        this.w750 = 1;
        if (bl > 1.5) {
          $("meta[name=viewport]").attr(
            "content",
            "width=750,user-scalable=no"
          );
        } else {
          $("meta[name=viewport]").attr(
            "content",
            "width=1280,user-scalable=no"
          );
        }
        this.$router.replace(`/clublistm/${this.id}/${this.lag}`);
        // window.location.href=`#/clublistm/${this.id}/${this.lag}`;
        // setTimeout(() => {
        //   window.location.reload();
        // }, 100);
      } else {
        $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
        this.m_group_2_height = 0;
        this.w750 = 0;
        // window.location.href = `#/index/${this.lag}`;
      }
    },

    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;

      this.pageHeight = pageHeight;

      $(".windowheight").css({ height: `${pageHeight}px` });
      setTimeout(() => {
        $(".windowheight").css({ height: `${this.pageHeight}px` });
      }, 1000);
      // // if (window.innerWidth > 1920) {
      // //   this.pageWidth = 1920;
      // // }
      // if (window.innerWidth <= 750) {
      //   this.sectionindex = 0;
      //   this.m_group_2_height = pageHeight;
      //   this.w750 = 1;
      //   window.location.href = `#/clublistm/${this.id}/${this.lag}`;
      // } else {
      //   this.m_group_2_height = 0;
      //   this.w750 = 0;
      // }

      this.resizeViewport();

      $("html").css({ fontSize: this.pageWidth / 192 });

      if (that.w750 == 0) {
        that.myswiper2();
      } else {
      }
    },
    changelag(lag) {
      if (lag == "en") {
        this.$router.push("index/en");
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        this.$router.push("/index");
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    },
    goDiv(classtext, url) {
      this.barclass = "closethis";
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });

      global.setIndexDclass("");
    },

    goIndex() {
      this.$router.push(`/indexl`);
    },

    goSelect(index) {
      this.$router.push(`/sindex/${index}/${this.lag}`);
    },

    goMindex(id2) {
      let url = "";
      let id = this.id;
      if (id == 0) {
        url = "fengxiang";
      }
      if (id == 1) {
        url = "huoban";
      }
      if (id == 2) {
        url = "";
      }
      if (id == 3) {
        url = "show";
      }
      if (id == 4) {
        url = "club";
      }

      if (url != "") {
        this.$router.push(`/${url}/${id2}/${this.lag}`);
      }
    },

    blinkshandleHover(index) {
      this.bselectindex = index;
      // console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},

    awardshandleHover(index) {
      this.awardsindex = index;
    },
    awardshandleLeave() {
      this.awardsindex = -1;
    },
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/indexl.css" />
<style src="../assets/css/indexl1280.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
